import { createStore } from 'vuex';
import Register from '@/store/modules/Register.js';
import Lang from '@/store/modules/Lang.js';
import News from '@/store/modules/News.js';
import Questions from '@/store/modules/Questions.js';
import Partners from '@/store/modules/Partners.js';
import QuizResult from '@/store/modules/QuizResult.js';
import GlobalModule from '@/store/modules/GlobalModule.js';
import GraphiksStore from '@/store/modules/GraphiksStore.js';

export default createStore({
  modules: {
    Register,
    Lang,
    News,
    Questions,
    Partners,
    QuizResult,
    GlobalModule,
    GraphiksStore
  },
});
