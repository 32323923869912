import Papa from 'papaparse';
export default {
	state: {
		chartDataGender:
		{
			labels: [],
			datasets:
			[
			{
				backgroundColor: [],
				data: []
			},
			],
		},
		chartDataCity:
		{
			labels: [],
			datasets:
			[
			{
				backgroundColor: [],
				data: []
			},
			],
		},
		chartDataAge:
		{
			labels: [],
			datasets:
			[
			{
				backgroundColor: [],
				data: []
			},
			],
		},
		chartDataCountry:[],
		chartDataAnonim:
		{
			labels: [],
			datasets:
			[
			{
				backgroundColor: [],
				data: []
			},
			],
		},
		chartDataOccupation:
		{
			labels: [],
			datasets:
			[
			{
				backgroundColor: [],
				data: []
			},
			],
		},
		chartDataOtlichniki:
		{
			labels: [],
			datasets:
			[
			{
				backgroundColor: [],
				data: []
			},
			],
		},
	},
	mutations: {
		SET_CHART_DATA_GENDER(state, data)
		{
			state.chartDataGender = data;
		},
		SET_CHART_DATA_CITY(state, data)
		{
			state.chartDataCity = data;
		},
		SET_CHART_DATA_OCCUPATION(state, data)
		{
			state.chartDataOccupation = data;
		},
		SET_CHART_DATA_AGE(state, data)
		{
			state.chartDataAge = data;
		},
		SET_CHART_DATA_COUNTRY(state, data)
		{
			state.chartDataCountry = data;
		},
		SET_CHART_DATA_ANONIM(state, data)
		{
			state.chartDataAnonim = data;
		},
		SET_CHART_DATA_OTLICHNIKI(state, data)
		{
			state.chartDataOtlichniki = data;
		},
	},
	actions: {
		loadCSVGender({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processGenderData',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV gender:", error);
				},
			});
		},
		loadCSVAge({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processAgeData',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV age:", error);
				},
			});
		},
		loadCSVAnonim({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processAgeAnonim',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV anonim:", error);
				},
			});
		},
		loadCSVCountry({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processCountryData',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV country:", error);
				},
			});
		},
		loadCSVCity({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processCityData',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV city:", error);
				},
			});
		},
		loadCSVOccupation({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processCityOccupation',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV occupation:", error);
				},
			});
		},
		loadCSVOtlichniki({ commit },file) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processCityOtlichniki',csvData, commit)
				},
				error: (error) => {
					console.error("Ошибка при парсинге CSV otlichniki:", error);
				},
			});
		},
		processGenderData({ commit }, data) {
			const male =
			{
				name: data[0].gender,
				count: +data[0].gender_count
			}
			const female =
			{
				name: data[1].gender,
				count: +data[1].gender_count
			}
			commit('SET_CHART_DATA_GENDER', {
				labels: [male.name, female.name],
				datasets: [{
					backgroundColor: ['#FF5733', '#89CFF0', '#52be80', '#f7dc6f'],
					data: [male.count, female.count],
				}],
			});
		},
		processAgeData({ commit }, data) {
			const ag1 =
			{
				name: data[0].age,
				count: +data[0].age_count
			}
			const ag2 =
			{
				name: data[1].age,
				count: +data[1].age_count
			}
			const ag3 =
			{
				name: data[2].age,
				count: +data[2].age_count
			}
			const ag4 =
			{
				name: data[3].age,
				count: +data[3].age_count
			}
			const ag5 =
			{
				name: data[4].age,
				count: +data[4].age_count
			}
			commit('SET_CHART_DATA_AGE', {
				labels: [ag1.name,ag2.name,ag3.name,ag4.name,ag5.name],
				datasets: [{
					backgroundColor: ['#FF5733', '#89CFF0', '#52be80', '#f7dc6f', '#f39c12', '#bb8fce'],
					data: [ag1.count,ag2.count,ag3.count,ag4.count,ag5.count],
				}],
			});
		},
		processCountryData({ commit }, data) {
			commit('SET_CHART_DATA_COUNTRY', data);
		},
		processAgeAnonim({ commit }, data) {
			let open =
			{
				name: data[0].anonim,
				count: +data[0].anonim_count
			}
			let anon =
			{
				name: data[1].anonim,
				count: +data[1].anonim_count
			}
			commit('SET_CHART_DATA_ANONIM', {
				labels: [open.name, anon.name],
				datasets: [{
					backgroundColor: ['#52be80','#FF5733', '#89CFF0', '#f7dc6f', '#f39c12', '#bb8fce'],
					data: [open.count, anon.count],
				}],
			});
		},
		processCityData({ commit }, data) {
			commit('SET_CHART_DATA_CITY', data);
		},
		processCityOccupation({ commit }, data)
		{
			const name = data.map(row => row.occupation)
			const count = data.map(row => row.occupation_count)
			commit('SET_CHART_DATA_OCCUPATION', {
				labels: name,
				datasets: [{
					backgroundColor: ['#FF5733', '#89CFF0', '#52be80', '#f7dc6f', '#f39c12', '#bb8fce'],
					data: count,
				}],
			});
		},
		processCityOtlichniki({ commit }, data)
		{
			const name = data.map(row => row.otlichniki)
			const count = data.map(row => row.otlichniki_count)
			commit('SET_CHART_DATA_OTLICHNIKI', {
				labels: name,
				datasets: [{
					backgroundColor: ['#FF5733', '#89CFF0', '#52be80', '#f7dc6f', '#f39c12', '#bb8fce'],
					data: count,
				}],
			});
		}
	}
}
