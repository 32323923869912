import { createRouter,createWebHistory } from "vue-router"

const routes =
[
  {
    name: 'home',
    path: '/',
    meta: {layout: 'default'},
    component: () => import("@/views/Index/IndexPage.vue"),
  },
  {
    name: 'homeAdmin',
    path: '/urdictantadmin=:id',
    meta: {layout: 'default'},
    component: () => import("@/views/Index/IndexPage.vue"),
  },
  {
    path: '/share/:id.pdf',
    name: 'Pdf',
    meta: {layout: 'default'},
    component: () => import("@/views/Pdf/Pdf.vue"),
  },
  {
    path: '/:lang/:id',
    name: 'Quiz',
    meta: {layout: 'default'},
    component: () => import("@/views/Quiz/Quiz.vue"),
  },
  {
    path: '/result/:id',
    name: 'Result',
    meta: {layout: 'default'},
    component: () => import("@/views/Result/Result.vue"),
  },
  {
    path: '/privacy/',
    name: 'Privacy',
    meta: {layout: 'default'},
    component: () => import("@/views/Privacy/Privacy.vue"),
  },
  {
    path: '/pdf/',
    name: 'Pdf',
    meta: {layout: 'default'},
    component: () => import("@/views/Pdf/Pdf.vue"),
  },
  {
    path: '/articles/:post',
    name: 'Articles',
    meta: {layout: 'default'},
    component: () => import("@/views/Articles/Articles.vue"),
  },
  {
    path: '/graphiks/urdictantadmin=Z0Q-tI7',
    name: 'Graphiks',
    meta: {layout: 'default'},
    component: () => import("@/views/Graphiks/Graphiks.vue"),
  },
  {
    path: '/Results',
    name: 'ShowResult',
    meta: {layout: 'default'},
    component: () => import("@/views/ShowResult/ShowResult.vue"),
  },
]

const router = createRouter(
{
  routes,
  history: createWebHistory(process.env.BASE_URL)
})
export default router;
