import {baseApi} from '@/api/BaseApi'
import { ElNotification } from "element-plus";
export default
{
  state:
  {
    questions: [],
    proQuest: [],
    basicQuest: [],
    businessQuest: [],
    // userRole: null,
    changeUnit: null,
  },
  mutations:
  {
    setQuestions(state, questions)
    {
      // console.log('store: ', questions.data.sort((a,b)=>+a.attributes.question_number > +b.attributes.question_number ? 1 : -1));
      state.proQuest = [];
      state.basicQuest = [];
      state.businessQuest = [];
      var regular = /\s*;\s*/g;
      state.questions = questions.data.sort((a,b)=>+a.attributes.question_number > +b.attributes.question_number ? 1 : -1)
      state.questions.forEach((el,index)=>
      {
        el.attributes.question = el.attributes.question.split(regular)
        el.attributes.correct_answer = el.attributes.correct_answer.split(regular)
        if(el.attributes.question_type == 'pro')
          state.proQuest.push(el);
        else if(el.attributes.question_type == 'business')
        {
          state.businessQuest.push(el);
        }
        else
        {
          state.basicQuest.push(el);
        }
        // el.attributes.correct_answer.indexOf(';') > -1 ?
        //   el.attributes.correct_answer = el.attributes.correct_answer.split(regular) :
        //     el.attributes.correct_answer
      })
      // console.log(state.proQuest)
    }
  },
  actions:
  {
    async GET_QUESTIONS({commit}, lang)
    {
      try {
        // console.log('store: ', lang);
        const response = await baseApi.getQuestions(lang);
        const responseJson = await response.json();
        commit('setQuestions', responseJson)
      } catch(e) {
        console.log(e);
      }
    },
  }
}
